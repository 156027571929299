import React from "react";
import { Link } from "gatsby"

const CookieConsent = () => {
  return (
    <div className="cookie-consent bottom-left">
      <div className="text-container">We use cookies to ensure you get the best experience on our website. View our <Link to={`/privacy-policy`} className="privacy-policy-link no-hover">privacy policy</Link>.</div>
      <button className="agree-consent" onClick={() => { window.CookieConsent.agree() }}>Got it!</button>
    </div>
  )
}

export default CookieConsent
