import React from "react"
import { Link } from "gatsby"

import Footer from '../components/footer'

import navigationLeft from '../content/settings/navigation-left.yml'
import navigationRight from '../content/settings/navigation-right.yml'
import CookieConsent from "./cookie-consent"

class Layout extends React.Component {

  render() {
    const { location, title, children } = this.props;
    const rootPath = `${__PATH_PREFIX__}/`;
    const isHome = location.pathname === rootPath;
    const header = (
      <div className="container">
        <div className="logo-container">
          <div className="logo-triangle"></div>
          <div className="logo-image">
            <Link to={`/`} className="no-hover">
              <img className="niwot-inn-icon" src="/images/niwot-inn-icon.svg" />
            </Link>
          </div>

        </div>
        <nav className="mobile-navigation">
          <input id="toggle" type="checkbox" />
          <label className="toggle-container" htmlFor="toggle">
            <div className="menu-btn">
              <div className="menu-text">
                <span className="open">Menu</span>
                <span className="close">Close</span>
              </div>
            </div>
          </label>
          <div className="nav-menu-wrapper">
            <div className="nav-menu-inner">
              <ul className="links">
                {navigationLeft.links.map((link, index) => {
                  return (
                    <li key={index}>
                      <Link to={link.path} activeClassName="active" className="no-hover">
                        {link.label}
                      </Link>
                    </li>
                  );
                })}
                {navigationRight.links.map((link, index) => {
                  return (
                    <li key={index}>
                      <Link to={link.path} activeClassName="active" className="no-hover">
                        {link.label}
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </nav>
        <nav className="left">
          <div className="nav-menu-wrapper">
              <div className="nav-menu-inner">
                <ul className="links">
                  {navigationLeft.links.map((link, index) => {
                    return (
                      <li key={index}>
                        <Link to={link.path} activeClassName="active" className="underline">
                          {link.label}
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
        </nav>
        <nav className="right">
          <div className="nav-menu-wrapper">
              <div className="nav-menu-inner">
                <ul className="links">
                  {navigationRight.links.map((link, index) => {
                    return (
                      <li key={index}>
                        <Link to={link.path} activeClassName="active" className="underline">
                          {link.label}
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
        </nav>
      </div>
    );
    return (
      <div className="page-container">
        <div className="container-wrapper">
          <header className="main">
            {header}
          </header>
          <main>
            {children}
          </main>
          </div>
          <CookieConsent />
          <Footer />
      </div>
    )
  }
}

export default Layout
