import React from 'react';
import ReactMarkdown from 'react-markdown'
import { Link } from 'gatsby'

const widgetPattern = /{"widget":"([a-zA-Z0-9-]+)","config":(.*?)}}/;

const lineRepresentsWidget = ( line ) => {
    if (!line || line.replace(/\s+/g, '') === '') return false;
	return RegExp(widgetPattern).test(line);
}

const renderWidgetFromLine = ( line, index ) => {
    var widgetObj = JSON.parse(line);
    if (widgetObj && widgetObj.widget && widgetObj.config) {
       if (widgetObj.widget === 'button') {
            return <Link
                        key={ index }
                        to={ widgetObj.config.url }
                        className={ 'button ' + widgetObj.config.classes }>
                        { widgetObj.config.text }
                    </Link>
       }
    }
    return {};
}

const renderHtmlFromLine = ( line, index ) => {
    return <ReactMarkdown key={ index }>{ line }</ReactMarkdown>
};

const renderWidgets = ( markdown ) => {
    var markdownLines = markdown && markdown.length ? markdown.split('\n') : [];
    var components = [];
    for (var i = 0; i < markdownLines.length; i++) {
        var line = markdownLines[i];
        if (lineRepresentsWidget(line)) {
            components.push( renderWidgetFromLine(line, i) );
        } else {
            components.push( renderHtmlFromLine(line, i) );
        }
    }
    return components;
};

class EnhancedMarkdown extends React.Component {
    render() {
        return renderWidgets(this.props.children || '');
    }
}

export default EnhancedMarkdown

