import React from "react";
import { Link } from "gatsby";
import EnhancedMarkdown from '../helpers/enhanced-markdown'

import { social, signUpText, emailForm } from '../content/settings/footer.yml'
import navigationLeft from '../content/settings/navigation-left.yml'
import navigationRight from '../content/settings/navigation-right.yml'

const Footer = () => {
    return (
    <footer className="main">
        <div className="container">
            <div className="row">
                <div className="footer-social col-100 col-small-1-2 col-medium-1-4 text-align-center text-align-small-left">
                    <Link to={`/`} className="no-hover">
                        <img className="niwot-inn-icon-with-text" src="/images/niwot-inn-icon-with-text.svg" />
                    </Link>
                    <div className="social-links">
                        {social.map(({ iconTitle, iconLink, iconClass}, i) => (
                            <a href={ iconLink } title={ iconTitle } className="no-hover">
                                <span className={ iconClass }></span>
                            </a>
                        ))}
                    </div>
                    <div className="olokoo-credit">
                        <span>Made with</span>
                        <span className="icon-heart"></span>
                        <span>by <Link to={`https://olokoo.com?ref=https://niwotinn.com`} target="_new" className="underline">Olokoo</Link>.</span>
                    </div>
                </div>
                <div className="footer-links col-100 col-small-1-2 col-medium-1-4 d-none d-small-inline-block">
                    <h3>More to explore</h3>
                    <div className="row">
                        <div className="col-1-2 col-medium-100 py-0">
                            <ul className="links">
                                {navigationLeft.links.map((link, index) => {
                                    return (
                                    <li key={index}>
                                        <Link to={link.path} className="no-hover">
                                        {link.label}
                                        </Link>
                                    </li>
                                    );
                                })}
                            </ul>
                        </div>
                        <div className="col-1-2 col-medium-100 py-0 py-medium-1">
                            <ul className="links">
                                {navigationRight.links.map((link, index) => {
                                    return (
                                    <li key={index}>
                                        <Link to={link.path} className="no-hover">
                                        {link.label}
                                        </Link>
                                    </li>
                                    );
                                })}
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="footer-email-signup col-100 col-small-100 col-medium-2-4">
                    <h3>Don't miss out</h3>
                    <div><EnhancedMarkdown>{ signUpText }</EnhancedMarkdown></div>
                    <form action={ emailForm.action } method="post" name="mc-embedded-subscribe-form" target="_blank" novalidate>
                        <input type="hidden" name="u" value={ emailForm.userId } />
                        <input type="hidden" name="id" value={ emailForm.audienceId } />
                        <div className="row">
                            <div className="form-group col-100 col-small-1-2">
                                <label className="form-label">Email address:</label>
                                <input className="form-input full-width" name={ emailForm.emailName } placeholder="Enter your email address" />
                            </div>
                            <div className="form-group col-100 col-small-1-2">
                                <label className="form-label">Your name:</label>
                                <input className="form-input col-1-2" name={ emailForm.customerFirstName } placeholder="First" style={{width: '48%', marginRight: '4%'}} />
                                <input className="form-input col-1-2" name={ emailForm.customerLastName } placeholder="Last" style={{width: '48%'}} />
                            </div>
                        </div>
                        <div className="row">
                            <div className="form-group col-100">
                                <button type="submit" className="button white-outline full-width">SIGN UP</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </footer>
    );
}
export default Footer